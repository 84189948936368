<template>
	<v-navigation-drawer
		id="nav"
		permanent
	>
		<v-list>
			<v-list-item
				href="#getting-started"
				link
			>
				<v-list-item-content>
					<v-list-item-title>Getting Started</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item
				href="#configuration"
				link
			>
				<v-list-item-content>
					<v-list-item-title>Configuration</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-divider/>

			<v-list-item
				href="#properties-file"
				link
			>
				<v-list-item-content>
					<v-list-item-title>&emsp;Properties File</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item
				href="#configuration-as-code"
				link
			>
				<v-list-item-content>
					<v-list-item-title>&emsp;Config-As-Code</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item
				href="#settings"
				link
			>
				<v-list-item-content>
					<v-list-item-title>Settings</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-divider/>

			<v-list-item
				href="#exclusions"
				link
			>
				<v-list-item-content>
					<v-list-item-title>&emsp;Exclusions</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item
				href="#masking"
				link
			>
				<v-list-item-content>
					<v-list-item-title>&emsp;Masking</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item
				href="#mutations"
				link
			>
				<v-list-item-content>
					<v-list-item-title>&emsp;Mutations</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item
				href="#symbols"
				link
			>
				<v-list-item-content>
					<v-list-item-title>&emsp;Symbols</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item
				href="#usage"
				link
			>
				<v-list-item-content>
					<v-list-item-title>Usage</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item
				href="#upcoming"
				link
			>
				<v-list-item-content>
					<v-list-item-title>&emsp;Upcoming Features</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item
				href="#versions"
				link
			>
				<v-list-item-content>
					<v-list-item-title>&emsp;Past Versions</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
	export default {
		name: "Navigation",
		data: () => ({
			entries: [
				"Getting Started",
				""
			]
		})
	}
</script>

<style scoped>

</style>
