<template>
	<v-footer
		id="footer"
		dark
		padless
		color="transparent"
	>
		<svg width="100vw"
			viewBox="0 0 1000 60">
			<path style="fill: var(--v-primary-base)"
				d="M 0 10
						C 100,0 100,0 250,30
						S 600,15 750,40
						S 900,30 1000,60
						L 0,60 Z"
			/>
		</svg>
		<v-card
			flat
			tile
			class="primary white--text text-center"
			width="100vw"
		>
			<v-card-text class="white--text">
				{{ new Date().getFullYear() }} — <strong>ChoreCore</strong>
			</v-card-text>
		</v-card>
	</v-footer>
</template>

<script>
	export default {
		name: "Footer"
	}
</script>

<style scoped>
	#footer {
		bottom: 0;
	}
</style>
