<template>
	<Prism
		class="code-snippet"
		:language="lang ? lang : 'kotlin'"
		:code="content"
	>
		<slot>

		</slot>
	</Prism>
</template>

<script>
	const {isTrue} = require("chorecore")
	import 'prismjs'
	import 'prismjs/components/prism-kotlin'
	import Prism from 'vue-prism-component'

	export default {
		name: "Snippet",
		components: {
			Prism
		},
		props: ["content", "lang"],
		data: () => ({
			isTrue
		}),
	}
</script>

<style scoped>
	.code-snippet {
		border: 1px solid;
		border-radius: 0.3em;

		overflow-x: scroll !important;
		white-space: pre;
	}

	.h-full {
		height: 100%;
	}

	.v-application code {
		background-color: transparent !important;
	}
</style>
