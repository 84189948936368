<template>
	<v-app-bar
		style="z-index: 1; position: fixed"
		color="accent"
		dark
	>
		<link rel="stylesheet"
			:href="isTrue($vuetify.theme.dark, 'https://unpkg.com/prismjs/themes/prism-tomorrow.css', 'https://unpkg.com/prismjs/themes/prism.css')"/>


		<v-app-bar-title>
			ToString
		</v-app-bar-title>

		<v-btn
			plain
			target="_blank"
			href="https://github.com/JTSchwartz/tostring"
		>
			GitHub
		</v-btn>

		<v-btn
			plain
			target="_blank"
			href="https://github.com/JTSchwartz/tostring/issues"
		>
			Report a Bug
		</v-btn>

		<v-spacer></v-spacer>

		<v-btn
			icon
			@click="toggleDarkMode()"
		>
			<v-icon v-if="$vuetify.theme.dark">
				fas fa-moon-stars
			</v-icon>
			<v-icon v-else>
				fas fa-sun
			</v-icon>
		</v-btn>
	</v-app-bar>
</template>

<script>
	const {isTrue} = require("chorecore")

	export default {
		name: "Header",
		props: ["atTop"],
		created() {
			if (typeof (Storage) !== "undefined") {
				if (localStorage.darkMode === "true") this.$vuetify.theme.dark = true
				this.toggleDarkMode(localStorage.darkMode === "true")
			}
		},
		data: () => ({
			isTrue
		}),
		methods: {
			toggleDarkMode: function (toggle = !this.$vuetify.theme.dark) {
				if (typeof (Storage) !== "undefined") localStorage.darkMode = toggle
				this.$vuetify.theme.dark = toggle
			}
		}
	}
</script>

<style scoped>
</style>
