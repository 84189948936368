<template>
	<v-app>
		<Header/>
		<Navigation/>
		<main>
			<h1>To String</h1>
			<p>
				Building strings out of objects in Java and Kotlin has long been a tedious task. Many developers opt to go the route of Lombok and use simple annotations to configure
				and generate a<code>toString()</code>that is human-readable. Unfortunately Lombok is not an option in Kotlin, it does not run an annotation processor at
				compile-time, leaving it dead in the water.
			</p>
			<p>
				While much of what Lombok brings to the table is baked into Kotlin, printing out an object without an overridden method will still just produce you a the class name
				and memory address; and building <code>toString()</code> methods can be tedious work and are often very brittle.
			</p>
			<p>
				Other libraries exist to create such methods, but are not dynamic and lack customization in the output.
			</p>
			<p>
				That is, until <strong>To String</strong> was created. <strong>To String</strong> is 100% dynamic, and allows for an incredible level of customization and more
				customization is coming with each and every update.
			</p>
			<h1 id="getting-started">Getting Started</h1>
			<p>
				To start, make sure that you add <strong>To String</strong> as a dependency
			</p>
			<v-row>
				<v-col>
					<h2>Gradle</h2>
					<Snippet content='implementation group: "com.jtschwartz", name: "tostring", version: "1.0.0"

// Or Kotlin DSL

implementation("com.jtschwartz:tostring:1.0.2")'/>
				</v-col>
				<v-col>
					<h2>Maven</h2>
					<Snippet
						lang="html"
						content="<dependency>
    <groupId>com.jtschwartz</groupId>
    <artifactId>tostring</artifactId>
    <version>1.0.2</version>
</dependency>
"/>
				</v-col>
			</v-row>
			<h1 id="configuration">Configuration</h1>
			<p>
				<strong>To String</strong> can be configured at a number of different levels, which can be mix-and-matched by preference on dependent on the needs of the
				environment it is running in.
			</p>
			<p>
				All settings are defaulted to a setup that should closely match the output of that of Lombok.
			</p>
			<br/>
			<h2 id="properties-file">Properties File</h2>
			<p>
				The first level of configuration is a properties file, specifically<code>tostring.properties</code>. These values get populated in to the<code>
				StringGenerationDefaults</code>object.
			</p>
			<p>
				In the next section we will cover this object, as well as the connection to the fields in the properties file.
			</p>
			<br/>
			<h2 id="configuration-as-code">Configuration-As-Code</h2>
			<p>
				The<code>StringGenerationDefaults</code>object is the heart of all the customizations; the properties file reads into it, and every instance of every<code>StringGenerationSettings</code>class
				pulls from object to lay it's groundwork.
			</p>
			<p>
				Below is a table outlining the fields of the object, the property they connect to, and their type signature. For a description of each, please find their respective
				section in the <em>Settings</em> portion of this documentation
			</p>
			<v-simple-table>
				<template v-slot:default>
					<thead>
					<tr>
						<th>Property</th>
						<th>Type</th>
					</tr>
					</thead>
					<tbody>
					<tr>
						<td><code>StringGenerationDefaults.exclusionSettings.excludePrivate</code></td>
						<td><code>tostring.exclude.private</code></td>
						<td><code>Boolean</code></td>
					</tr>
					<tr>
						<td><code>StringGenerationDefaults.exclusionSettings.excludeNull</code></td>
						<td><code>tostring.exclude.null</code></td>
						<td><code>Boolean</code></td>
					</tr>
					<tr>
						<td><code>StringGenerationDefaults.exclusionSettings.excludeUninitialized</code></td>
						<td><code>tostring.exclude.uninit</code></td>
						<td><code>Boolean</code></td>
					</tr>
					<tr>
						<td><code>StringGenerationDefaults.exclusionSettings.exclusionList</code></td>
						<td><code>tostring.exclude.list</code></td>
						<td><code>List&lt;String&gt;</code></td>
					</tr>
					<tr>
						<td><code>StringGenerationDefaults.postfixMaskSettings.inclusionList</code></td>
						<td><code>tostring.mask.post.list</code></td>
						<td><code>List&lt;String&gt;</code></td>
					</tr>
					<tr>
						<td><code>StringGenerationDefaults.postfixMaskSettings.visibleCount</code></td>
						<td><code>tostring.mask.post.visible</code></td>
						<td><code>Int</code></td>
					</tr>
					<tr>
						<td><code>StringGenerationDefaults.postfixMaskSettings.maskCharacter</code></td>
						<td><code>tostring.mask.post.mask</code></td>
						<td><code>Char</code></td>
					</tr>
					<tr>
						<td><code>StringGenerationDefaults.postfixMaskSettings.maxMaskCharCount</code></td>
						<td><code>tostring.mask.post.max</code></td>
						<td><code>Int</code></td>
					</tr>
					<tr>
						<td><code>StringGenerationDefaults.prefixMaskSettings.inclusionList</code></td>
						<td><code>tostring.mask.pre.list</code></td>
						<td><code>List&lt;String&gt;</code></td>
					</tr>
					<tr>
						<td><code>StringGenerationDefaults.prefixMaskSettings.visibleCount</code></td>
						<td><code>tostring.mask.pre.visible</code></td>
						<td><code>Int</code></td>
					</tr>
					<tr>
						<td><code>StringGenerationDefaults.prefixMaskSettings.maskCharacter</code></td>
						<td><code>tostring.mask.pre.mask</code></td>
						<td><code>Char</code></td>
					</tr>
					<tr>
						<td><code>StringGenerationDefaults.prefixMaskSettings.maxMaskCharCount</code></td>
						<td><code>tostring.mask.pre.max</code></td>
						<td><code>Int</code></td>
					</tr>
					<tr>
						<td><code>StringGenerationDefaults.mutationSettings.propertyNameMutation</code></td>
						<td>N/A</td>
						<td><code>(String) -> String</code></td>
					</tr>
					<tr>
						<td><code>StringGenerationDefaults.mutationSettings.propertyValueMutation</code></td>
						<td>N/A</td>
						<td><code>(Any?) -> String</code></td>
					</tr>
					<tr>
						<td><code>StringGenerationDefaults.symbolSettings.assignment</code></td>
						<td><code>tostring.symbols.assignment</code></td>
						<td><code>String</code></td>
					</tr>
					<tr>
						<td><code>StringGenerationDefaults.symbolSettings.closingBracket</code></td>
						<td><code>tostring.symbols.closing</code></td>
						<td><code>String</code></td>
					</tr>
					<tr>
						<td><code>StringGenerationDefaults.symbolSettings.displayClassName</code></td>
						<td><code>tostring.symbols.display_name</code></td>
						<td><code>Boolean</code></td>
					</tr>
					<tr>
						<td><code>StringGenerationDefaults.symbolSettings.openingBracket</code></td>
						<td><code>tostring.symbols.opening</code></td>
						<td><code>String</code></td>
					</tr>
					<tr>
						<td><code>StringGenerationDefaults.symbolSettings.separator</code></td>
						<td><code>tostring.symbols.separator</code></td>
						<td><code>String</code></td>
					</tr>
					<tr>
						<td><code>StringGenerationDefaults.symbolSettings.uninitializedValue</code></td>
						<td><code>tostring.symbols.uninit</code></td>
						<td><code>String</code></td>
					</tr>
					</tbody>
				</template>
			</v-simple-table>
			<br/>
			<h1 id="settings">Settings</h1>
			<p>
				Configuration sets up, but it is not required to use those settings. The<code>StringGenerationSettings</code>class allows for further changes to be made at time of
				calling for string generation should you require it. The<code>StringGenerationSettings</code>class does not actually contain any settings itself, but instead wraps up 5
				sub-classes that contain categorized settings, thus code must be written and less objects must be passed about. It can be created as such:
			</p>
			<Snippet content="val settings = StringGenerationSettings(
				exclusionSettings = ExclusionSettings(...),
				mutationSettings = MutationSettings(...),
				postfixMaskSettings = PostfixMaskSettings(...),
				prefixMaskSettings = PrefixMaskSettings(...),
				symbolSettings = SymbolSettings(...))"/>
			<br/>
			<p>
				It is important to note that this class, along will all sub-classes, are data classes, thus allowing for simple copying and changing via the<code>copy()</code>method,
				and all fields are contained in the constructor so that nothing will be missed out.
			</p>
			<p>
				All fields from the sub-classes have a default in the<code>StringGenerationDefaults</code>object. The following sections will display the settings, their correlating
				field in the object, their default value, and a description.
			</p>
			<br/>
			<h2 id="exclusions">Exclusion Settings</h2>
			<p>
				Fairly self explanatory, but allows for removing properties from display based on a few different flags, or their name.
			</p>
			<v-simple-table>
				<template v-slot:default>
					<thead>
					<tr>
						<th>Parameter</th>
						<th>Default Property</th>
						<th>Default Value</th>
						<th>Description</th>
					</tr>
					</thead>
					<tbody>
					<tr>
						<td><code>excludePrivate</code></td>
						<td><code>exclusionSettings.excludePrivate</code></td>
						<td><code>false</code></td>
						<td>
							Should private fields be left out.
						</td>
					</tr>
					<tr>
						<td><code>excludeNull</code></td>
						<td><code>exclusionSettings.excludeNull</code></td>
						<td><code>false</code></td>
						<td>
							Should null values be left out.
						</td>
					</tr>
					<tr>
						<td><code>excludeUninitialized</code></td>
						<td><code>exclusionSettings.excludeUninitialized</code></td>
						<td><code>false</code></td>
						<td>
							Should uninitialized values be left out.
						</td>
					</tr>
					<tr>
						<td><code>exclusionList</code></td>
						<td><code>exclusionSettings.exclusionList</code></td>
						<td><code>[]</code></td>
						<td>
							A list of property names that should be left out.
						</td>
					</tr>
					</tbody>
				</template>
			</v-simple-table>
			<br/>
			<h2 id="masking">Masking Settings</h2>
			<p>
				When it comes to masking, there are 2 different options: Prefix & Postfix masking. Prefix masking means the prefix is mask and the latter portion of the string is visible,
				like how you would expect a credit card number to be masked. The opposite is handled by Postfix masking, usually for items like names.
			</p>
			<p>
				These properties are broken up into their respective class:<code>PostfixMaskSettings</code>or<code>PrefixMaskSettings</code>.
			</p>
			<v-simple-table>
				<template v-slot:default>
					<thead>
					<tr>
						<th>Parameter</th>
						<th>Default Property</th>
						<th>Default Value</th>
						<th>Description</th>
					</tr>
					</thead>
					<tbody>
					<tr>
						<td><code>inclusionList</code></td>
						<td><code>postfixMaskSettings.inclusionList</code></td>
						<td><code>[]</code></td>
						<td>
							A list of property names whose values should be subject to masking.
						</td>
					</tr>
					<tr>
						<td><code>visibleCount</code></td>
						<td><code>postfixMaskSettings.visibleCount</code></td>
						<td><code>3</code></td>
						<td>
							How many characters should be visible at the beginning of the string.
						</td>
					</tr>
					<tr>
						<td><code>maskCharacter</code></td>
						<td><code>postfixMaskSettings.maskCharacter</code></td>
						<td><code>'*'</code></td>
						<td>
							The character that will replace portions of the string that should not be seen.
						</td>
					</tr>
					<tr>
						<td><code>maxMaskCharCount</code></td>
						<td><code>postfixMaskSettings.maxMaskCharCount</code></td>
						<td><code>0</code></td>
						<td>
							If greater than zero, this field will trim down how many of the mask characters are displayed.
						</td>
					</tr>
					<tr>
						<td><code>inclusionList</code></td>
						<td><code>prefixMaskSettings.inclusionList</code></td>
						<td><code>[]</code></td>
						<td>
							A list of property names whose values should be subject to masking.
						</td>
					</tr>
					<tr>
						<td><code>visibleCount</code></td>
						<td><code>prefixMaskSettings.visibleCount</code></td>
						<td><code>3</code></td>
						<td>
							How many characters should be visible at the end of the string.
						</td>
					</tr>
					<tr>
						<td><code>maskCharacter</code></td>
						<td><code>prefixMaskSettings.maskCharacter</code></td>
						<td><code>'*'</code></td>
						<td>
							The character that will replace portions of the string that should not be seen.
						</td>
					</tr>
					<tr>
						<td><code>maxMaskCharCount</code></td>
						<td><code>prefixMaskSettings.maxMaskCharCount</code></td>
						<td><code>0</code></td>
						<td>
							If greater than zero, this field will trim down how many of the mask characters are displayed.
						</td>
					</tr>
					</tbody>
				</template>
			</v-simple-table>
			<br/>
			<h2 id="mutations">Mutation Settings</h2>
			<p>
				Mutations are lambdas executed on the property name or value, these are invoked prior to masking.
			</p>
			<v-simple-table>
				<template v-slot:default>
					<thead>
					<tr>
						<th>Parameter</th>
						<th>Default Property</th>
						<th>Signature</th>
						<th>Default Value</th>
						<th>Description</th>
					</tr>
					</thead>
					<tbody>
					<tr>
						<td><code>propertyNameMutation</code></td>
						<td><code>mutationSettings.propertyNameMutation</code></td>
						<td><code>(String) -> String</code></td>
						<td><code>{ it }</code></td>
						<td>
							A lambda to mutate the appearance of a property name.
						</td>
					</tr>
					<tr>
						<td><code>propertyValueMutation</code></td>
						<td><code>mutationSettings.propertyValueMutation</code></td>
						<td><code>(Any?) -> String</code></td>
						<td><code>{ it?.toString() ?: "null" }</code></td>
						<td>
							A lambda to mutate the appearance of a property value.
						</td>
					</tr>
					</tbody>
				</template>
			</v-simple-table>
			<br/>
			<h2 id="symbols">Symbol Settings</h2>
			<p>
				These values change the appearance of the output that does not directly affect any of the properties of the context object.
			</p>
			<v-simple-table>
				<template v-slot:default>
					<thead>
					<tr>
						<th>Parameter</th>
						<th>Default Property</th>
						<th>Default Value</th>
						<th>Description</th>
					</tr>
					</thead>
					<tbody>
					<tr>
						<td><code>assignment</code></td>
						<td><code>symbolSettings.assignment</code></td>
						<td><code>"="</code></td>
						<td>
							The value the separates a property's name from its value.
						</td>
					</tr>
					<tr>
						<td><code>closingBracket</code></td>
						<td><code>symbolSettings.closingBracket</code></td>
						<td><code>")"</code></td>
						<td>
							The last symbol which wraps up all properties.
						</td>
					</tr>
					<tr>
						<td><code>displayClassName</code></td>
						<td><code>symbolSettings.displayClassName</code></td>
						<td><code>true</code></td>
						<td>
							Whether or not to print the context object's class name ahead of the opening bracket.
						</td>
					</tr>
					<tr>
						<td><code>openingBracket</code></td>
						<td><code>symbolSettings.openingBracket</code></td>
						<td><code>")"</code></td>
						<td>
							The first symbol which wraps up all properties.
						</td>
					</tr>
					<tr>
						<td><code>separator</code></td>
						<td><code>symbolSettings.separator</code></td>
						<td><code>", "</code></td>
						<td>
							The symbol that discerns one property from the next.
						</td>
					</tr>
					<tr>
						<td><code>uninitializedValue</code></td>
						<td><code>symbolSettings.uninitializedValue</code></td>
						<td><code>"UNINITIALIZED"</code></td>
						<td>
							The string that should be displayed as the value for any fields that have not been initialized.
						</td>
					</tr>
					</tbody>
				</template>
			</v-simple-table>
			<br/>
			<h1 id="usage">Usage</h1>
			<p>
				Finally, we can put all this together and actually call the lone method for all of this,<code>generateString()</code>. The intention of this library is for overriding
				object's<code>toString()</code>method, but this can also be called from wherever as an extension method if you require a string-ified version of an object somewhere else:
			</p>
			<Snippet content="override toString() = generateString()

// OR

val str = someObject.generateString()"/>
			<br/>
			<p>
				And don't forget, you can pass in an instance of<code>StringGenerationSettings</code>if you want to make changes past the defaults (this example shows all fields being
				changed, but all fields have defaults so none are obviously required). We do however recommend storing an instance of custom settings in companion objects to save memory
				space, though none of this library is large in size by any means.
			</p>
			<Snippet :content="complete"/>
			<br/>
			<h1 id="roadmap">Roadmap</h1>
			<h2 id="upcoming">Upcoming Features</h2>
			<ul>
				<li>Themes: Defaults that can be changed as a whole. Expect the current (lombok) theme, JSON, and possibly Yaml.</li>
				<li>More customization on the displayed class name</li>
				<li>More default mutation lambdas for commonly used changes and changes based on a property value's type</li>
				<li>New line support as a flag rather than implemented through the separator field</li>
			</ul>
			<br/>
			<p>
				If you have any requested features, please feel free to open an issue or pull request on <a target="_blank" href="https://github.com/JTSchwartz/tostring">GitHub</a>.
			</p>
			<br/>
			<h2 id="versions">Past Versions</h2>
			<v-simple-table>
				<template v-slot:default>
					<thead>
					<tr>
						<th>Version</th>
						<th>Features</th>
						<th>Bugs Fixed</th>
					</tr>
					</thead>
					<tbody>
					<tr>
						<td>1.0.2</td>
						<td>None</td>
						<td>separator property was misspelled</td>
					</tr>
					<tr>
						<td>1.0.1</td>
						<td>None</td>
						<td>excludePrivate property wasn't populated from properties</td>
					</tr>
					<tr>
						<td>1.0.0</td>
						<td>
							<ul>
								<li>Exclusion Settings</li>
								<li>Postfix Mask Settings</li>
								<li>Prefix Mask Settings</li>
								<li>Mutation Settings</li>
								<li>Symbol Settings</li>
							</ul>
						</td>
						<td>Initial Release</td>
					</tr>
					</tbody>
				</template>
			</v-simple-table>
		</main>
		<Footer/>
	</v-app>
</template>

<script>
	import Footer from "./components/Footer";
	import Header from "./components/Header";
	import Navigation from "./components/Navigation";
	import Snippet from "./components/Snippet";

	export default {
		name: 'App',

		components: {
			Snippet,
			Navigation,
			Header,
			Footer,
		},

		data: () => ({
			offsetTop: 0,
			complete: `val stringSettings = StringGenerationSettings(
\t\t\t\texclusionSettings = ExclusionSettings(
\t\t\t\t\texcludeNull = false
\t\t\t\t\texcludeUninitialized = true
\t\t\t\t\texclusionList = listOf("personalInformation", "confidentialInfo")
\t\t\t\t\texcludePrivate = true
\t\t\t\t),
\t\t\t\tpostfixMaskSettings = PostfixMaskSettings(
\t\t\t\t\tinclusionList = listOf("firstName", "lastName")
\t\t\t\t\tvisibleCount = 3
\t\t\t\t\tmaskCharacter = '*'
\t\t\t\t\tmaxMaskCharCount = 4
\t\t\t\t),
\t\t\t\tprefixMaskSettings = PrefixMaskSettings(
\t\t\t\t\tinclusionList = listOf("creditCardNumber")
\t\t\t\t\tvisibleCount = 4
\t\t\t\t\tmaskCharacter = '#'
\t\t\t\t\tmaxMaskCharCount = 0
\t\t\t\t),
\t\t\t\tmutationSettings = MutationSettings(
\t\t\t\t\tpropertyNameMutation = { "[\${it.uppercase()}]" }
\t\t\t\t\tpropertyValueMutation = { it?.toString() ?: "Null Value" }
\t\t\t\t),
\t\t\t\tsymbolSettings = SymbolSettings(
\t\t\t\t\tassignment = ": "
\t\t\t\t\tclosingBracket = "{ "
\t\t\t\t\tdisplayClassName = false
\t\t\t\t\topeningBracket = " }"
\t\t\t\t\tseparator = " | "
\t\t\t\t\tuninitializedValue = "Not value set"
\t\t\t\t)
)

override toString() = generateString(stringSettings)`
		}),
		methods: {
			onScroll(e) {
				this.offsetTop = e.target.scrollTop
			},
		}
	};
</script>

<style>
	::-webkit-scrollbar {
		width: 0;
		height: 0;
	}

	::-webkit-scrollbar-thumb {
		background: transparent;
		border-radius: 0;
	}

	::-webkit-scrollbar-thumb:hover {
		background: transparent;
	}

	::-webkit-scrollbar-track {
		background: transparent;
		border-radius: 0;
	}

	aside, header {
		position: fixed;
	}

	aside {
		padding-top: 4rem;
	}

	main {
		padding: 6rem 4rem 0 18rem;
	}

	h1, h2, h3, h4, h5, h6 {
		padding-bottom: 1rem;
	}

	p {
		padding-left: 1rem;
	}

	.v-application code {
		background-color: transparent !important;
	}
</style>
